export default {
  blogerName: 'Numeiro',
  socialsList: [
    {
      name: 'kick',
      url: 'https://kick.com/numeiro',
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/numeiro10',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/numeiro/'
    }
  ],
  projects: [
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/c24e1aef3',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://monrocasino.life/cc3d18a81',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c2f56a08e',
      gameTitle: 'STARDA QUEEN',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>NUM1</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'NUM1',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
  attentionText: ''
}
