<template>
  <div
      v-if="attentionText"
      class="attention"
  >{{attentionText}}</div>
  <footer class="footer"></footer>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "footer",
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      counterDomainType: this.config.counterDomainType,
      attentionText: this.config.attentionText
    }
  }
})
</script>

<style lang="scss" src="./styles.scss"></style>
